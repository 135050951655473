import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Media, Row } from "reactstrap"
import { deleteGodown, getGodownDetails } from "store/actions"
import GodownCreateModal from "../Crud/godownCreateModal"
import DeleteModal from "components/Common/DeleteModal"

function Details() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [godownModal, setGodownModal] = useState(false)
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const { godownDetails } = useSelector(state => ({
    godownDetails: state.Godown.godownDetails,
  }))

  useEffect(() => {
    dispatch(getGodownDetails(params?.id))
  }, [dispatch])

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteGodown(params?.id, history))
  }

  console.log(params?.id)
  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  return (
    <div>
      <GodownCreateModal
        show={godownModal}
        onCloseClick={() => setGodownModal(false)}
        isUpdate={godownDetails}
      />
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Card>
        <Row>
          <Col md={6} className="">
            <div className="d-flex justify-content-between">
              <CardBody className="text-start mb-0 pb-0">
                <div className="text-muted">
                  <div className="mb-2">
                    <label className="text-muted font-size-14 m-0 p-0">
                      Name :{" "}
                    </label>{" "}
                    &nbsp;
                    <p
                      style={{ fontWeight: 500 }}
                      className="font-size-14 text-black m-0 p-0"
                    >
                      {godownDetails?.name}
                    </p>
                  </div>

                  <div className="mb-2">
                    <label className="text-muted font-size-14 m-0 p-0">
                      Phone :&nbsp;
                    </label>
                    <p
                      style={{ fontWeight: 500 }}
                      className="font-size-14 text-black m-0 p-0"
                    >
                      {godownDetails?.phone}
                    </p>
                  </div>
                  <div className="mb-2">
                    <label className="text-muted font-size-14 m-0 p-0">
                      Place :&nbsp;
                    </label>
                    <p
                      style={{ fontWeight: 500 }}
                      className="font-size-14 text-black m-0 p-0"
                    >
                      {godownDetails?.place}
                    </p>
                  </div>
                  <p>
                    Joined on &nbsp;
                    <span className="text-black">
                      {moment(godownDetails?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-start p-3">
                  <div className="mx-2">
                    <Button
                      className="px-5"
                      color="light"
                      onClick={() => setGodownModal(true)}
                    >
                      Edit
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => handleDelete(params?.id)}
                      className="px-5"
                      color="danger"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Details
