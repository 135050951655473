import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import LocalLogo from "assets/images/scorio/logo.png"
import LocalLogosm from "assets/images/scorio/logo.png"

import { Link } from "react-router-dom"

//actions
import { getSettings } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"

const Sidebar = props => {
  const dispatch = useDispatch()

  const { settings, loading, sideBarSm } = useSelector(state => ({
    settings: state.Settings.settings,
    loading: state.Banner.loading,
    sideBarSm: state.Layout.sideBarSm,
  }))

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={
                  sideBarSm === false
                    ? settings?.company?.logo[0]?.url ?? LocalLogo
                    : LocalLogosm
                }
                alt="company logo"
                height="22"
              />
            </span>
            <span className="logo-lg">
              <img
                src={settings?.company?.logo[0]?.ur ?? LocalLogo}
                alt=""
                height="35"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light"></Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent props />
          ) : (
            <SidebarContent />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
