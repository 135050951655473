export const GET_ALL_GODOWN = "GET_ALL_GODOWN"
export const GET_ALL_GODOWN_SUCCESS = "GET_ALL_GODOWN_SUCCESS"
export const GET_ALL_GODOWN_FAIL = "GET_ALL_GODOWN_FAIL"

// single view

export const GET_GODOWN_DETAILS = "GET_GODOWN_DETAILS"
export const GET_GODOWN_DETAILS_SUCCESS = "GET_GODOWN_DETAILS_SUCCESS"
export const GET_GODOWN_DETAILS_FAIL = "GET_GODOWN_DETAILS_FAIL"

// create

export const CREATE_GODOWN = "CREATE_GODOWN"
export const CREATE_GODOWN_SUCCESS = "CREATE_GODOWN_SUCCESS"
export const CREATE_GODOWN_FAIL = "CREATE_GODOWN_FAIL"

// update

export const UPDATE_GODOWN = "UPDATE_GODOWN"
export const UPDATE_GODOWN_SUCCESS = "UPDATE_GODOWN_SUCCESS"
export const UPDATE_GODOWN_FAIL = "UPDATE_GODOWN_FAIL"

// delete

export const DELETE_GODOWN = "DELETE_GODOWN"
export const DELETE_GODOWN_SUCCESS = "DELETE_GODOWN_SUCCESS"
export const DELETE_GODOWN_FAIL = "DELETE_GODOWN_FAIL"
