import {
  CREATE_GODOWN,
  CREATE_GODOWN_FAIL,
  CREATE_GODOWN_SUCCESS,
  DELETE_GODOWN,
  DELETE_GODOWN_FAIL,
  DELETE_GODOWN_SUCCESS,
  GET_ALL_GODOWN,
  GET_ALL_GODOWN_FAIL,
  GET_ALL_GODOWN_SUCCESS,
  GET_GODOWN_DETAILS,
  GET_GODOWN_DETAILS_FAIL,
  GET_GODOWN_DETAILS_SUCCESS,
  UPDATE_GODOWN,
  UPDATE_GODOWN_FAIL,
  UPDATE_GODOWN_SUCCESS,
} from "./actionTypes"

export const getAllGodown = (page, limit, searchText) => ({
  type: GET_ALL_GODOWN,
  payload: { page, limit, searchText },
})
export const getAllGodownSuccess = godowns => ({
  type: GET_ALL_GODOWN_SUCCESS,
  payload: godowns,
})

export const getAllGodownFail = error => ({
  type: GET_ALL_GODOWN_FAIL,
  payload: error,
})
// SINGLE VIEW

export const getGodownDetails = godownId => ({
  type: GET_GODOWN_DETAILS,
  payload: godownId,
})

export const getGodownDetailsSuccess = godownDetails => ({
  type: GET_GODOWN_DETAILS_SUCCESS,
  payload: godownDetails,
})

export const getGodownDetailsFail = error => ({
  type: GET_GODOWN_DETAILS_FAIL,
  payload: error,
})

// CREATE

export const createGodown = (godown, history, onCloseClick) => ({
  type: CREATE_GODOWN,
  payload: { godown, history, onCloseClick },
})

export const createGodownSuccess = godown => ({
  type: CREATE_GODOWN_SUCCESS,
  payload: godown,
})

export const createGodownFail = error => ({
  type: CREATE_GODOWN_FAIL,
  payload: error,
})

export const updateGodown = (godown, godownId, onCloseClick) => ({
  type: UPDATE_GODOWN,
  payload: { godown, godownId, onCloseClick },
})

export const updateGodownSuccess = godown => ({
  type: UPDATE_GODOWN_SUCCESS,
  payload: godown,
})

export const updateGodownFail = error => ({
  type: UPDATE_GODOWN_FAIL,
  payload: error,
})

// delete

export const deleteGodown = (godownId, history) => ({
  type: DELETE_GODOWN,
  payload: { godownId, history },
})

export const deleteGodownSuccess = godownId => ({
  type: DELETE_GODOWN_SUCCESS,
  payload: godownId,
})

export const deleteGodownFail = error => ({
  type: DELETE_GODOWN_FAIL,
  payload: error,
})
