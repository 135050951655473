import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { createGodown, updateGodown } from "store/actions"

const GodownCreateModal = ({ show, onCloseClick, isUpdate }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  function handleValidSubmit(event, values) {
    if (isUpdate) {
      dispatch(updateGodown(values, params?.id, onCloseClick))
    } else {
      dispatch(createGodown(values, history, onCloseClick))
    }
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4 pb-0">
        <p className="mb-3">{isUpdate ? "Update" : "Create"} Godown</p>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
        >
          <Row className="mb-3">
            <Col className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}>
              <AvField
                label={"Name"}
                id={`input-id-name`}
                className="filePathClass"
                name={"name"}
                type={"text"}
                value={isUpdate?.name}
              />
            </Col>
            <Col className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}>
              <AvField
                label={"phone"}
                id={`input-id-Phone`}
                className="filePathClass"
                name={"phone"}
                type={"number"}
                value={isUpdate?.phone}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Phone Number is Required",
                  },

                  minLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 characters",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 characters",
                  },
                }}
              />
            </Col>
            <Col className={`col-12  mb-4`}>
              <AvField
                label={"place"}
                id={`input-id-Phone`}
                className="filePathClass"
                name={"place"}
                type={"text"}
                value={isUpdate?.place}
              />
            </Col>
            <Col className="text-end">
              <button
                onClick={onCloseClick}
                className="btn btn-light me-3 w-md "
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-success w-md " type="submit">
                {isUpdate ? "Update" : "Create"}
              </button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

GodownCreateModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  isUpdate: PropTypes.any,
}

export default GodownCreateModal
