import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAIL,
  GET_ALL_PRODUCTS_LIST,
  GET_ALL_PRODUCTS_LIST_SUCCESS,
  GET_ALL_PRODUCTS_LIST_FAIL,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS,
  GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL,
  GET_DELETED_PRODUCTS,
  GET_DELETED_PRODUCTS_SUCCESS,
  GET_DELETED_PRODUCTS_FAIL,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAIL,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_BULK_PRODUCTS,
  UPDATE_BULK_PRODUCTS_SUCCESS,
  UPDATE_BULK_PRODUCTS_FAIL,
  CHANGE_PRODUCT_STATUS,
  CHANGE_PRODUCT_STATUS_SUCCESS,
  CHANGE_PRODUCT_STATUS_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  HARD_DELETE_PRODUCT,
  HARD_DELETE_PRODUCT_SUCCESS,
  HARD_DELETE_PRODUCT_FAIL,
  RESTORE_PRODUCT,
  RESTORE_PRODUCT_SUCCESS,
  RESTORE_PRODUCT_FAIL,
  CREATE_DUPLICATE_PRODUCT,
  CREATE_DUPLICATE_PRODUCT_SUCCESS,
  CREATE_DUPLICATE_PRODUCT_FAIL,
  UNDO_REFRASH,
} from "./actionTypes"

// export const getProducts = page => ({
//   type: GET_PRODUCTS,
//   payload: { page },
// })

// export const getProductsSuccess = products => ({
//   type: GET_PRODUCTS_SUCCESS,
//   payload: products,
// })

// export const getProductsFail = error => ({
//   type: GET_PRODUCTS_FAIL,
//   payload: error,
// })

//all
export const getAllProducts = (page, sort, limit, searchText, store) => ({
  type: GET_ALL_PRODUCTS,
  payload: { page, sort, limit, searchText, store },
})

export const getAllProductsSuccess = products => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: products,
})

export const getAllProductsFail = error => ({
  type: GET_ALL_PRODUCTS_FAIL,
  payload: error,
})

//all List
export const getAllProductsList = store => ({
  type: GET_ALL_PRODUCTS_LIST,
  payload: store,
})

export const getAllProductsListSuccess = productsList => ({
  type: GET_ALL_PRODUCTS_LIST_SUCCESS,
  payload: productsList,
})

export const getAllProductsListFail = error => ({
  type: GET_ALL_PRODUCTS_LIST_FAIL,
  payload: error,
})

//all For Download
export const getAllProductsForDownload = godown => ({
  type: GET_ALL_PRODUCTS_FOR_DOWNLOAD,
  payload: godown,
})

export const getAllProductsForDownloadSuccess = productsForDownload => ({
  type: GET_ALL_PRODUCTS_FOR_DOWNLOAD_SUCCESS,
  payload: productsForDownload,
})

export const getAllProductsForDownloadFail = error => ({
  type: GET_ALL_PRODUCTS_FOR_DOWNLOAD_FAIL,
  payload: error,
})

export const getDeletedProducts = () => ({
  type: GET_DELETED_PRODUCTS,
})

export const getDeletedProductsSuccess = products => ({
  type: GET_DELETED_PRODUCTS_SUCCESS,
  payload: products,
})

export const getDeletedProductsFail = error => ({
  type: GET_DELETED_PRODUCTS_FAIL,
  payload: error,
})

export const getProductDetails = productId => ({
  type: GET_PRODUCT_DETAILS,
  payload: productId,
})

export const getProductDetailsSuccess = productDetails => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload: productDetails,
})

export const getProductDetailsFail = error => ({
  type: GET_PRODUCT_DETAILS_FAIL,
  payload: error,
})

export const createProduct = (product, history) => ({
  type: CREATE_PRODUCT,
  payload: { product, history },
})

export const createProductSuccess = product => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: product,
})

export const createProductFail = error => ({
  type: CREATE_PRODUCT_FAIL,
  payload: error,
})

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAILS,
  productId,
})

export const updateProduct = (product, productId, history, godownId) => ({
  type: UPDATE_PRODUCT,
  payload: { product, productId, history, godownId },
})

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
})

export const updateBulkProducts = (bulkProducts, godownId) => ({
  type: UPDATE_BULK_PRODUCTS,
  payload: { bulkProducts, godownId },
})

export const updateBulkProductsSuccess = data => ({
  type: UPDATE_BULK_PRODUCTS_SUCCESS,
  payload: data,
})

export const updateBulkProductsFail = error => ({
  type: UPDATE_BULK_PRODUCTS_FAIL,
  payload: error,
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})

export const changeProductStatus = (productId, history) => ({
  type: CHANGE_PRODUCT_STATUS,
  payload: { productId, history },
})

export const changeProductStatusSuccess = product => ({
  type: CHANGE_PRODUCT_STATUS_SUCCESS,
  payload: product,
})

export const changeProductStatusFail = error => ({
  type: CHANGE_PRODUCT_STATUS_FAIL,
  payload: error,
})

export const deleteProduct = (productId, history) => ({
  type: DELETE_PRODUCT,
  productId,
  history,
})

export const deleteProductSuccess = product => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
})

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
})

export const hardDeleteProduct = (productId, history) => ({
  type: HARD_DELETE_PRODUCT,
  productId,
  history,
})

export const hardDeleteProductSuccess = product => ({
  type: HARD_DELETE_PRODUCT_SUCCESS,
  payload: product,
})

export const hardDeleteProductFail = error => ({
  type: HARD_DELETE_PRODUCT_FAIL,
  payload: error,
})

export const restoreProduct = (productId, history) => ({
  type: RESTORE_PRODUCT,
  productId,
  history,
})

export const restoreProductSuccess = product => ({
  type: RESTORE_PRODUCT_SUCCESS,
  payload: product,
})

export const restoreProductFail = error => ({
  type: RESTORE_PRODUCT_FAIL,
  payload: error,
})

//Duplicate Product

export const createDuplicateProduct = (productId, history) => ({
  type: CREATE_DUPLICATE_PRODUCT,
  payload: { productId, history },
})

export const createDuplicateProductSuccess = product => ({
  type: CREATE_DUPLICATE_PRODUCT_SUCCESS,
  payload: product,
})

export const createDuplicateProductFail = error => ({
  type: CREATE_DUPLICATE_PRODUCT_FAIL,
  payload: error,
})

export const undoRefrash = () => ({
  type: UNDO_REFRASH,
})
