import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_BLOGS,
  CREATE_BLOG,
  GET_BLOG_DETAILS,
  DELETE_BLOG,
  UPDATE_BLOG,
} from "./actionTypes"
import {
  getBlogsSuccess,
  getBlogsFail,
  getBlogDetailsSuccess,
  getBlogDetailsFail,
  createBlogFail,
  createBlogSuccess,
  updateBlogSuccess,
  updateBlogFail,
  deleteBlogSuccess,
  deleteBlogFail,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function fetchAllBlogApi({ page, limit, searchText, sort }) {
  return get(
    `/blog/admin/all?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText && searchText}&sort=${sort}`
  )
}

const getBlogDetailsAPi = blogId => {
  return get(`/blog/admin/${blogId}`)
}

function createBlogApi(blog) {
  return post("/blog/admin/new", blog)
}

function updateBlogApi({ blog, blogId }) {
  return ApiPut(`/blog/admin/${blogId}`, blog)
}

function deleteBlogApi(blogId) {
  return del(`/blog/admin/${blogId}`)
}

function* fetchBlogs({ payload }) {
  try {
    const response = yield call(fetchAllBlogApi, payload)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getBlogsFail(error))
  }
}

function* fetchBlogDetails({ payload: blogId }) {
  try {
    const response = yield call(getBlogDetailsAPi, blogId)
    yield put(getBlogDetailsSuccess(response))
  } catch (error) {
    yield put(getBlogDetailsFail(error))
  }
}

function* onCreateBlog({ payload: { blog, onCloseClick } }) {
  try {
    const response = yield call(createBlogApi, blog)
    if (response?._id) {
      onCloseClick()

      Notification({
        type: "success",
        message: "Blog Created Successfully!",
        title: "",
      })

      yield put(createBlogSuccess(response))
    }
  } catch (error) {
    console.log("title =>")
    console.log("data :", error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createBlogFail(error))
  }
}

function* onUpdateBlog({ payload }) {
  try {
    const response = yield call(updateBlogApi, payload)
    if (response?._id) {
      if (payload?.onCloseClick) {
        payload.onCloseClick()
      }
      if (payload?.needReload) {
        console.log("title =>", true)
        console.log("data :", payload)
        try {
          const response = yield call(fetchAllBlogApi, payload?.pagination)
          yield put(getBlogsSuccess(response))
        } catch (error) {
          yield put(getBlogsFail(error))
        }
      }
      Notification({
        type: "success",
        message: "Blog Updated Successfully!",
        title: "",
      })
      yield put(updateBlogSuccess(response))
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateBlogFail(error))
  }
}

function* onDeleteBlog({ blogId }) {
  try {
    const response = yield call(deleteBlogApi, blogId)
    yield put(deleteBlogSuccess(response))
    Notification({
      type: "success",
      message: "Blog Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteBlogFail(error))
  }
}

function* blogSaga() {
  yield takeEvery(GET_BLOGS, fetchBlogs)
  yield takeEvery(GET_BLOG_DETAILS, fetchBlogDetails)
  yield takeEvery(CREATE_BLOG, onCreateBlog)
  yield takeEvery(UPDATE_BLOG, onUpdateBlog)
  yield takeEvery(DELETE_BLOG, onDeleteBlog)
}

export default blogSaga
