import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Button, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

//actions
import { LanguageSwitch } from "common/LanguageSwitch"
import { deleteCoupon, getCoupons, updateCoupon } from "store/coupon/actions"

import "../../assets/scss/datatables.scss"

import DeleteModal from "components/Common/DeleteModal"

const Coupons = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [limit, setLimit] = useState("10")

  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  const { coupons, company, tableLoad } = useSelector(state => ({
    coupons: state.Coupon.coupons,
    tableLoad: state.Coupon.tableLoad,
    company: state.Settings.settings.company,
  }))
  const defaultCurrencySymbol = company?.defaultCurrencySymbol

  useEffect(() => {
    dispatch(getCoupons(pageSend(), limit, searchText))
  }, [dispatch, page, searchText])

  const handleSwitchChange = (e, item) => {
    const updateCouponData = {
      ...item,
      isActive: e.target.checked,
    }
    dispatch(updateCoupon(updateCouponData, item?._id, history))
  }

  const total = coupons?.length

  //pagination
  const totalPages = Math.ceil(total / limit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "couponCode",
      text: "Coupon Code",
      sort: true,
    },
    {
      dataField: "couponType",
      text: "Coupon Type",
      sort: true,
    },
    {
      dataField: "redeemed",
      text: "Redeemed",
      sort: true,
    },
    {
      dataField: "dedected",
      text: "Total Dedected",
      sort: true,
    },
    {
      dataField: "activeData",
      text: "Status ",
    },

    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const couponData = map(coupons, (item, index) => ({
    ...item,
    discountPercentage: (
      <p className="m-0 p-0">
        {" "}
        {item.discountPercentage ? item.discountPercentage : 0}%
      </p>
    ),
    couponType:
      item.couponType === "Custom Percentage" ? (
        <span>
          {item.couponType} - ({item.discountPercentage} %)
        </span>
      ) : (
        <span>
          {item.couponType} - ({item.discountValue})
        </span>
      ),
    redeemed: <span>{item.deductedUsersList.length}</span>,
    dedected: (
      <span className="text-center">
        {defaultCurrencySymbol} {item.totalDeductionAmount}
      </span>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={e => {
              handleSwitchChange(e, item)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),

    action: (
      <div>
        <Link to={`/coupons/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceCouponSearch = debounce(value => setSearchText(value), 600)

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteCoupon(isOpen?.id))
  }

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const isMult = LanguageSwitch()?.isMult

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={
                    isMult
                      ? columns
                      : columns?.filter(
                          i => i.dataField !== "secondaryLang.title"
                        )
                  }
                  data={couponData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e =>
                                    debounceCouponSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Link to="/coupons/create/new">
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                              >
                                <i className="mdi mdi-plus me-1" />
                                Create Coupon
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {tableLoad ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {coupons?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Coupons!</p>
                          )}
                          <MyPagination
                            pages={pages}
                            clcickedPage={page}
                            onNunClick={item => setPage(item)}
                            onNextClick={() => setPage(page + 1)}
                            onPrevClick={() => setPage(page - 1)}
                            apiPage={pageSend}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Coupons
