import { useHistory } from "react-router"
import { Col, Container, Row } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useParams } from "react-router-dom"
import Select from "react-select"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/scss/custom.scss"

//actions
import {
  getCouponsDetails,
  getSettings,
  getUsers,
  updateCoupon,
} from "store/actions"

import { map } from "lodash"
import { LanguageSwitch } from "common/LanguageSwitch"
import moment from "moment"
import { CouponDuration, couponTypeOption } from "./Create"
import MetaTag from "components/Common/Meta-tag"

const UpdateCoupon = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, error, couponDetails, company, users, userload } =
    useSelector(state => ({
      loading: state.Coupon.loading,
      error: state.Coupon.error,
      couponDetails: state.Coupon.couponDetails,
      company: state.Settings.settings.company,
      users: state.Users.users?.users,
      userload: state.Users.loading,
    }))

  const defaultCurrencySymbol = company?.defaultCurrencySymbol

  const [couponDuration, setCouponDuration] = useState()
  const [selectedMulti, setselectedMulti] = useState(null)
  const [selectedUsers, setSelectedUser] = useState([])

  console.log(selectedUsers)

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  useEffect(() => {
    if (couponDetails?.durationType) {
      setCouponDuration(couponDetails?.durationType)
    }
  }, [couponDetails?.durationType])

  useEffect(() => {
    dispatch(getCouponsDetails(params?.id))
  }, [dispatch])

  function handleValidSubmit(values) {
    const updateCouponData = {
      name: values.name,
      couponCode: values.couponCode,
      couponType: values.couponType,
      durationType: couponDuration,
      validFrom: values.validFrom,
      validTo: values.validTo,
      discountPercentage: values.discountPercentage,
      discountValue: values.discountValue,
      maxValue: values.maxValue,
      totalUserLimit: values.totalUserLimit,
      perUserLimit: values.perUserLimit,
      whitelistUsersList: selectedUsers,
    }

    dispatch(updateCoupon(updateCouponData, params?.id, history))
  }

  const isMult = LanguageSwitch()?.isMult

  const optionGroup = users?.map(user => {
    return {
      value: user._id,
      label: user.name,
    }
  })

  function handleMulti(userData) {
    setselectedMulti(userData)
    const arrangedUsers = userData?.map(a => a.value)
    setSelectedUser(arrangedUsers)
  }

  useEffect(() => {
    dispatch(getUsers(1, ""))
  }, [dispatch])

  useEffect(() => {
    const userList = couponDetails?.whitelistUsersList

    const newList = map(userList, i => ({
      ...selectedMulti,
      label: i?.name ? i?.name : i?.shippingAddresses[0]?.shippingInfo?.name,
      value: i?._id,
    }))

    setselectedMulti(newList)

    const idList = map(userList, i => i?._id)

    setSelectedUser(idList)
  }, [couponDetails?.whitelistUsersList])

  return (
    <>
      <MetaTag title="Coupon" />

      <div className="page-content">
        <Breadcrumbs title="Coupons" breadcrumbItem="Coupon" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="8" className="m-auto">
                  <Card
                    style={{
                      borderRadius: "20px",
                    }}
                  >
                    <CardBody
                      className="bg-green"
                      style={{
                        minHeight: "150px",
                        borderRadius: "20px",
                        padding: "0",
                      }}
                    >
                      <Row style={{}}>
                        <Col
                          md={8}
                          lg={8}
                          sm={8}
                          xs={8}
                          xl={8}
                          className="col-8 d-flex custom-dashed flex-wrap p-4 pl-5"
                          style={{
                            alignItems: "flex-end",
                          }}
                        >
                          <div className="p-2 pb-0">
                            <h4>{couponDetails?.name}</h4>
                            <p>{couponDetails?.couponType}</p>

                            <span className="d-flex">
                              <h6 className="text-muted">Total user limit :</h6>{" "}
                              <p className="mx-2 mb-0">
                                {couponDetails?.totalUserLimit}
                              </p>
                            </span>
                            <span className="d-flex ">
                              <h6 className="mb-0 text-muted">
                                Per User Limit :{" "}
                              </h6>{" "}
                              <p className="mx-2 mb-0">
                                {couponDetails?.perUserLimit}
                              </p>
                            </span>
                          </div>
                          <div>
                            <ul className="mb-0">
                              <li className="my-2">
                                Coupon Redeemed: {couponDetails?.deductionCount}
                              </li>
                              <li className="my-2">
                                Redeemed Users:{" "}
                                {couponDetails?.deductedUsersList?.length
                                  ? couponDetails?.deductedUsersList?.length
                                  : 0}
                              </li>
                              <li className="mt-2">
                                Total Redeemed Amount: {defaultCurrencySymbol}
                                &nbsp;
                                {couponDetails?.totalDeductionAmount}
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          md={4}
                          sm={4}
                          xm={4}
                          xl={4}
                          className="p-4 bg-yellow"
                          style={{
                            // maxWidth: "255px",
                            borderRadius: " 0 20px 20px 0",
                          }}
                        >
                          <h2>{couponDetails?.couponCode}</h2>
                          <h6>
                            Discount Percentage :{" "}
                            {couponDetails?.discountPercentage}%
                          </h6>
                          {couponDetails?.discountValue ? (
                            <h6>
                              Discount Value : {couponDetails?.discountValue}
                            </h6>
                          ) : (
                            ""
                          )}
                          {/* <h6>
                            Max Redeemable Value :{" "}
                            {couponDetails?.discountValue}
                          </h6> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Update Coupon :</CardTitle>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Name
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="name"
                              value={couponDetails?.name ?? ""}
                              required
                              label=""
                              type="text"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 text_deals_green 
                            "
                          >
                            Coupon Code
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              value={couponDetails?.couponCode ?? ""}
                              name="couponCode"
                              required
                              label=""
                              type="text"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Coupon Type
                          </CardTitle>
                          <AvField
                            type="select"
                            name="couponType"
                            value={couponDetails?.couponType ?? ""}
                            required
                          >
                            <option>Select a Coupon Type</option>
                            {couponTypeOption?.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <Row>
                      <Col xl="6" md="12">
                        <CardBody>
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Duration
                          </CardTitle>
                          <Row>
                            <div
                              className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                              role="group"
                              aria-label="Basic radio toggle button group"
                            >
                              {map(CouponDuration, (item, key) => (
                                <div key={key} className="me-3">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="durationType"
                                    id={item.id}
                                    autoComplete="off"
                                    value={item.value}
                                    checked={item.value === couponDuration}
                                    onChange={e =>
                                      setCouponDuration(e.target.value)
                                    }
                                  />
                                  <label
                                    className="btn btn-outline-info"
                                    htmlFor={item.id}
                                  >
                                    {item.title}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Row>
                        </CardBody>
                      </Col>
                      {couponDuration === "Full time" ? (
                        <></>
                      ) : (
                        <Col xl="6" md="12">
                          <CardBody>
                            <Row>
                              <Col md="6" sm="12">
                                <CardTitle className="h4 mb-4 text_deals_green">
                                  Falid From
                                </CardTitle>
                                <div className="mb-3">
                                  <AvField
                                    name="validFrom"
                                    label=""
                                    type="date"
                                    value={moment(
                                      couponDetails?.validFrom
                                    ).format("yyyy-MM-DD")}
                                  />
                                </div>
                              </Col>

                              {couponDuration === "Start time" ? (
                                <></>
                              ) : (
                                <Col md="6" sm="12">
                                  <CardTitle
                                    className="h4 mb-4 text_deals_green 
                                    "
                                  >
                                    Valid To
                                  </CardTitle>
                                  <div className="mb-3">
                                    <AvField
                                      name="validTo"
                                      value={moment(
                                        couponDetails?.validTo
                                      ).format("yyyy-MM-DD")}
                                      label=""
                                      type="date"
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </CardBody>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Discount Percentage
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="discountPercentage"
                              label=""
                              type="number"
                              value={couponDetails?.discountPercentage ?? ""}
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 text_deals_green 
                            "
                          >
                            Discount Value
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="discountValue"
                              label=""
                              type="number"
                              value={couponDetails?.discountValue ?? ""}
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            Max Value
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="maxValue"
                              value={couponDetails?.maxValue ?? ""}
                              label=""
                              type="number"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="12" lg="12" md="12" sm="12" className="mb-3">
                          <CardTitle className="h4 mb-2 text_deals_green">
                            Whitelist Users
                          </CardTitle>
                          <div className="mb-3">
                            <Select
                              value={selectedMulti}
                              isMulti={true}
                              onChange={e => {
                                handleMulti(e)
                              }}
                              options={optionGroup}
                              isLoading={userload}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="6" md="6" sm="12">
                          <CardTitle className="h4 mb-4 text_deals_green">
                            {/* Maximum no.of People per Coupon */}
                            Total user limit
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="totalUserLimit"
                              value={couponDetails?.totalUserLimit ?? ""}
                              label=""
                              type="phone"
                            />
                          </div>
                        </Col>

                        <Col xl="4" lg="6" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 text_deals_green 
                            "
                          >
                            Per user limit
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="perUserLimit"
                              label=""
                              type="phone"
                              value={couponDetails?.perUserLimit ?? ""}
                            />
                          </div>
                        </Col>

                        {/* <Col xl="4" lg="4" md="6" sm="12">
                          <CardTitle
                            className="h4 mb-4 text_deals_green"

                            
                          >
                            Deduction Count
                          </CardTitle>
                          <div className="mb-3">
                            <AvField
                              name="deductionCount"
                              label=""
                              type="number"
                            />
                          </div>
                        </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                          {error?.message && (
                            <p
                              className="mt-2 mb-0 pb-0"
                              style={{ textAlign: "end", color: "#ff0000a3" }}
                            >
                              {" "}
                              <span>!</span> {error?.message}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCoupon
