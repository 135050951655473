import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import JoditEditor from "jodit-react"

const FormEditors = ({ content, setContent }) => {
  const editor = useRef(null)

  return (
    <Fragment>
      <div className="db__column">
        <JoditEditor
          ref={editor}
          value={content}
          onChange={text => setContent(text)}
        />
      </div>
    </Fragment>
  )
}

export default FormEditors

FormEditors.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
}
