import React, { useEffect, useState } from "react"
import { Row, Col, Spinner, Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../MyPagination"

import "../../../assets/scss/datatables.scss"
import moment from "moment"
import { CurrencySwitch } from "common/Currency"
import { Link, useParams } from "react-router-dom"
import { getUserOrderDetails } from "store/actions"

const OrderDatatable = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { orders, userOrderDetails, loading } = useSelector(state => ({
    orders: state.Orders.userOrderDetails.orders,
    userOrderDetails: state.Orders.userOrderDetails,
    loading: state.Users.loading,
  }))

  const [page, setPage] = useState(1)

  const total = userOrderDetails?.totalOrders

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const columns = [
    {
      dataField: "orderId",
      text: "Order Id",
    },
    {
      dataField: "createdAt",
      text: "Date",
    },
    {
      dataField: "items",
      text: "Total Items",
    },
    {
      dataField: "paymentType",
      text: "PaymentType",
      sort: true,
    },
    {
      dataField: "itemsPrice",
      text: "Items Total",
      sort: true,
    },
    {
      dataField: "shippingPrice",
      text: "Shipping Total",
      sort: true,
    },
    {
      dataField: "couponValue",
      text: "Coupon Discount",
      sort: true,
    },
    {
      dataField: "taxValue",
      text: "VAT Total (15%)",
      sort: true,
    },
    {
      dataField: "totalPrice",
      text: "Gross Total",
      sort: true,
    },
  ]

  const currency = CurrencySwitch()

  const userCartData = map(orders, (item, index) => ({
    ...item,
    orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    createdAt: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {moment(item?.createdAt).format("DD-MM-YY / h:mm a")}
      </p>
    ),
    paymentType: (
      <Badge
        className={
          item?.paymentType === "Cash on Delivery"
            ? "font-size-12 badge-soft-primary"
            : "font-size-12 badge-soft-success"
        }
        pill
      >
        {item?.paymentType}
      </Badge>
    ),
    items: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {item?.orderItems?.length}
      </p>
    ),
    itemsPrice: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {currency} {item?.itemsPrice?.toFixed(2)}
      </p>
    ),
    shippingPrice: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {currency} {item?.shippingPrice?.toFixed(2)}
      </p>
    ),
    couponValue: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {currency} {item?.couponValue?.toFixed(2)}
      </p>
    ),
    taxValue: (
      <p style={{ fontSize: "13px" }} className="m-0 p-0">
        {currency}
        {item?.taxValue?.toFixed(2)}
      </p>
    ),
    totalPrice: (
      <p style={{ fontSize: "15px" }} className="m-0 p-0">
        {currency}
        {item?.totalPrice?.toFixed(2)}
      </p>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  // useEffect(() => {
  //   dispatch(getUsers(pageSend()))
  // }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getUserOrderDetails(params.id, pageSend()))
  }, [dispatch, pageSend()])

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userCartData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // keyField={"id"}
                            key={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      pages={pages}
                      clcickedPage={page}
                      onNunClick={item => setPage(item)}
                      onNextClick={() => setPage(page + 1)}
                      onPrevClick={() => setPage(page - 1)}
                      apiPage={pageSend}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrderDatatable
