import { Notification } from "components/Common/Notification"
import { ApiPut, del, get, post } from "helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createGodownFail,
  createGodownSuccess,
  deleteGodownFail,
  deleteGodownSuccess,
  deleteGOdownSuccess,
  getAllGodownFail,
  getAllGodownSuccess,
  getGodownDetailsFail,
  getGodownDetailsSuccess,
  updateGodownFail,
  updateGodownSuccess,
} from "./actions"
import {
  CREATE_GODOWN,
  DELETE_GODOWN,
  GET_ALL_GODOWN,
  GET_GODOWN_DETAILS,
  UPDATE_GODOWN,
} from "./actionTypes"

function getAllGodownApi({ page, limit, searchText }) {
  if (searchText) {
    return get(
      `/godown/all?search=${searchText && searchText}&page=${
        page ? page : 1
      }&limit=${limit && limit}`
    )
  } else {
    return get(`/godown/all?page=${page ? page : 1}&limit=${limit && limit}`)
  }
}
function getGodownDetailsAPi(godownId) {
  return get(`/godown/${godownId}`)
}

function createGodownApi({ godown }) {
  return post(`/godown/new`, godown)
}

const updateGodownApi = ({ godown, godownId }) => {
  console.log(godownId)
  return ApiPut(`/godown/${godownId}`, godown)
}
const deleteGodownApi = ({ godownId }) => {
  console.log("godownId", godownId)
  return del(`/godown/${godownId}`)
}

function* fetchAllGodowns({ payload }) {
  try {
    const response = yield call(getAllGodownApi, payload)
    yield put(getAllGodownSuccess(response))
  } catch (error) {
    yield put(getAllGodownFail(error))
  }
}

function* fetchGodownDetails({ payload: productId }) {
  try {
    const response = yield call(getGodownDetailsAPi, productId)

    yield put(getGodownDetailsSuccess(response.data))
  } catch (error) {
    yield put(getGodownDetailsFail(error))
  }
}

function* onCreateGodown({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createGodownApi, payload)
    yield put(createGodownSuccess(response))
    Notification({
      type: "success",
      message: "Godown Created Successfully",
      title: "",
    })
    payload?.onCloseClick()
    // payload.history.push("/godowns")
  } catch (error) {
    console.log(error)
    yield put(createGodownFail(error))
  }
}
function* onUpdateGodown({ payload }) {
  try {
    const response = yield call(updateGodownApi, payload)

    if (response) {
      Notification({
        type: "success",
        message: "Godown Updated Successfully!",
        title: "",
      })
      yield put(updateGodownSuccess(response))
      payload?.onCloseClick()
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }

    yield put(updateGodownFail(error.response))
  }
}

function* onDeleteGodown({ payload }) {
  console.log(payload?.history)
  try {
    const response = yield call(deleteGodownApi, payload)
    yield put(deleteGodownSuccess(response))
    Notification({
      type: "success",
      message: "Godown Deleted Successfully!",
      title: "",
    })
    payload.history.push("/godowns")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteGodownFail(error?.response?.data))
  }
}

function* godownSaga() {
  yield takeEvery(GET_ALL_GODOWN, fetchAllGodowns)
  yield takeEvery(GET_GODOWN_DETAILS, fetchGodownDetails)
  yield takeEvery(CREATE_GODOWN, onCreateGodown)
  yield takeEvery(UPDATE_GODOWN, onUpdateGodown)
  yield takeEvery(DELETE_GODOWN, onDeleteGodown)
}

export default godownSaga
