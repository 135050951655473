import {
  CREATE_GODOWN,
  CREATE_GODOWN_FAIL,
  CREATE_GODOWN_SUCCESS,
  DELETE_GODOWN,
  DELETE_GODOWN_FAIL,
  DELETE_GODOWN_SUCCESS,
  GET_ALL_GODOWN,
  GET_ALL_GODOWN_FAIL,
  GET_ALL_GODOWN_SUCCESS,
  GET_GODOWN_DETAILS,
  GET_GODOWN_DETAILS_FAIL,
  GET_GODOWN_DETAILS_SUCCESS,
  UPDATE_GODOWN,
  UPDATE_GODOWN_FAIL,
  UPDATE_GODOWN_SUCCESS,
} from "./actionTypes"

const Ini_State = {
  tableLoading: false,
  godowns: [],
  error: {},
  loading: false,
  godown: {},
  godownDetails: {},
}

const Godown = (state = Ini_State, action) => {
  switch (action.type) {
    case GET_ALL_GODOWN:
      return {
        ...state,
        tableLoading: true,
      }

    case GET_ALL_GODOWN_SUCCESS:
      return {
        ...state,
        tableLoading: false,
        godowns: action.payload,
      }
    case GET_ALL_GODOWN_FAIL:
      return {
        ...state,
        tableLoading: false,
        error: action.payload,
      }

    //single view

    case GET_GODOWN_DETAILS:
      return {
        ...state,
        loading: true,
      }

    case GET_GODOWN_DETAILS_SUCCESS:
      return {
        ...state,
        godownDetails: action.payload,

        loading: false,
      }

    case GET_GODOWN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //create

    case CREATE_GODOWN:
      return {
        ...state,
        loading: true,
      }
    case CREATE_GODOWN_SUCCESS:
      return {
        ...state,
        loading: false,

        godowns: {
          ...state?.godowns,
          godowns: [...state.godowns?.godowns, action.payload],
        },
      }

    case CREATE_GODOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_GODOWN:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_GODOWN_SUCCESS:
      return {
        ...state,

        loading: false,
        godownDetails: action.payload,

        godowns: {
          ...state?.godowns,
          godowns: state.godowns?.godowns?.map(list =>
            list?._id === action.payload?._id
              ? { ...list, isActive: action.payload.isActive }
              : list
          ),
        },
      }

    case UPDATE_GODOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_GODOWN:
      return {
        ...state,
        loading: true,
      }
    case DELETE_GODOWN_SUCCESS:
      return {
        ...state,
        godowns: {
          ...state?.godowns,
          godowns: state.godowns?.godowns.filter(
            godown => godown._id !== action.payload._id
          ),
        },

        // godowns: {
        //   ...state?.godowns,
        //   godowns: [...state.godowns?.godowns, action.payload],
        // },
        loading: false,
      }

    case DELETE_GODOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
export default Godown
