import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"

import noImage from "../../assets/images/product/noimage3.png"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

//actions
import { getUsers, deleteUser, updateUser } from "store/users/actions"

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Users = () => {
  const dispatch = useDispatch()
  const { users, total, loading } = useSelector(state => ({
    users: state.Users.users?.users,
    total: state.Users.users?.total,
    loading: state.Users.loading,
  }))

  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("user")
  const [userSearchText, setUserSearchText] = useState("")

  const handleDelete = id => {
    dispatch(deleteUser(id, history))
  }

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const handleSwitchChange = (e, item) => {
    dispatch(updateUser({ ...item, isPrime: e.target.checked }, item?._id))
  }

  useEffect(() => {
    dispatch(getUsers(pageSend(), sort, userSearchText))
  }, [dispatch, page, sort, userSearchText])

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "roleData",
      text: "Role",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Joined On",
      sort: true,
    },
    {
      dataField: "lastLoginData",
      text: "Last Login",
      sort: true,
    },
    {
      dataField: "activeData",
      filterText: "status",
      text: "Prime User",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const storeAdminColums = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    ...columns
      ?.filter(i => i?.dataField !== "activeData")
      ?.filter(y => y.dataField !== "roleData"),
  ]

  const userData = map(users, (item, index) => ({
    ...item,
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={item?.images && item?.images[0] ? item?.images[0]?.url : noImage}
      />
    ),
    roleData: (
      <Badge
        className={
          item.isGuest
            ? "font-size-12 badge-soft-primary"
            : item.role == "admin"
            ? "font-size-12 badge-soft-secondary"
            : "font-size-12 badge-soft-success"
        }
        color=""
        pill
      >
        {item.isGuest ? "guest" : item.role}
      </Badge>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm A"),

    lastLoginData:
      item.lastLogin && moment(item.lastLogin).format("DD/MM/YYYY - h:mm A"),

    activeData: (
      <>
        {item.role === "user" || item.role === "prime-user" ? (
          <div className="form-check form-switch form-switch-md mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
              onChange={e => {
                handleSwitchChange(e, item)
              }}
              checked={item?.isPrime}
            />
            <label className="form-check-label" htmlFor="customSwitchsizemd">
              {item?.isPrime ? `Yes` : `No`}
            </label>
          </div>
        ) : (
          ""
        )}
      </>
    ),
    action: (
      <div>
        <Link to={`users/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        <Button
          id={`delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item?._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          View Details
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`delete${index}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const listChange = sortValue => {
    setSort(sortValue)
  }

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)

  const handleTableColumn = () => {
    if (sort === "store-admin" || sort === "admin") {
      return storeAdminColums
    } else if (sort === "all") {
      return columns
    } else {
      return columns?.filter(i => i.dataField !== "roleData")
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col className="mb-3 col-12">
                  <div
                    className="btn-group mt-2 mt-xl-0"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="allbtnradio"
                      autoComplete="off"
                      onChange={() => listChange("all")}
                    />
                    <label className="btn btn-success" htmlFor="allbtnradio">
                      All
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="userbtnradio"
                      autoComplete="off"
                      defaultChecked
                      onChange={() => listChange("user")}
                    />
                    <label className="btn btn-success" htmlFor="userbtnradio">
                      User
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="primeuserbtnradio"
                      autoComplete="off"
                      onChange={() => listChange("prime-user")}
                    />
                    <label
                      className="btn btn-success"
                      htmlFor="primeuserbtnradio"
                    >
                      Prime User
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="storeadminbtnradio"
                      autoComplete="off"
                      onChange={() => listChange("store-admin")}
                    />
                    <label
                      className="btn btn-success"
                      htmlFor="storeadminbtnradio"
                    >
                      Store Admin
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="adminbtnradio"
                      autoComplete="off"
                      onChange={() => listChange("admin")}
                    />
                    <label className="btn btn-success" htmlFor="adminbtnradio">
                      Admin
                    </label>
                  </div>
                </Col>
              </Row>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={handleTableColumn()}
                  data={userData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              {/* <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" /> */}
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e =>
                                    debounceUserSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {users?.length ? (
                            <Row>
                              <p>Total Users : {total}</p>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No {sort === "all" ? "User" : sort}s!
                            </p>
                          )}
                          <MyPagination
                            pages={pages}
                            clcickedPage={page}
                            onNunClick={item => setPage(item)}
                            onNextClick={() => setPage(page + 1)}
                            onPrevClick={() => setPage(page - 1)}
                            apiPage={pageSend}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Users
