import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Spinner,
  CardTitle,
} from "reactstrap"
import orderImg from "assets/images/order.svg"
import productImg from "assets/images/product.svg"
import storeImg from "assets/images/store.svg"

//actions
import { getDashboardData } from "store/dashboard/actions"
import MetaTag from "components/Common/Meta-tag"
import { map } from "lodash"
import { Link } from "react-router-dom"

const Dashboard = () => {
  const dispatch = useDispatch()

  const { dashboardData, loading, classificationInputs } = useSelector(
    state => ({
      dashboardData: state.Dashboard.dashboardData,
      loading: state.Dashboard.loading,
      classificationInputs:
        state.Settings.settings?.setup?.classificationInputs,
    })
  )

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  const TopCards = [
    {
      iconClass: orderImg,
      title: "Orders",
      description: dashboardData?.ordersCount ?? 0,
      title2: "New",
      description2: dashboardData?.newOrdersCount ?? 0,
      title3: "Today",
      description3: dashboardData?.todayOrdersCount ?? 0,

      bgColor: "bg-green",
      subList: true,
      list: [
        // {
        //   sbadges: "Orders",
        //   sbadgesRate: dashboardData?.ordersCount,
        //   sbadgesClass: "success",
        // },
      ],
      Link: "/orders",
      col: "col-12 col-sm-12 col-xs-12 col-md-12 col-lg-6 col-xl-6",
    },

    {
      title: "Products",
      iconClass: productImg,
      description: dashboardData?.productsCount ?? 0,
      bgColor: "bg-yellow",
      subList: true,

      list: [
        {
          sbadges: "Stock Out Products",
          sbadgesRate: dashboardData?.outOfStockProductsCount,
          sbadgesClass: "warning",
        },
        {
          sbadges: "Stock Out Varients",
          sbadgesRate: dashboardData?.outOfStockVarientsCount,
          sbadgesClass: "warning",
        },
      ],
      Link: "/products",
      col: "col-12  col-xs-6 col-sm-6 col-md-12 col-lg-6 col-xl-6",
    },
    // {
    //   title: "Stores",
    //   iconClass: storeImg,
    //   description: dashboardData?.totalStores ?? 0,
    //   bgColor: "bg-whiteSmook",
    //   subList: false,
    //   list: [],
    //   Link: "/stores",
    //   col: "col-12  col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
    // },
  ]

  const reports = [
    // {
    //   title: "Stores",
    //   iconClass: "bx bx-store-alt",
    //   description: `${dashboardData?.totalStores}`,
    //   wbadges: "",
    //   wbadgesRate: "",
    //   sbadges: "",
    //   sbadgesRate: "",
    //   sbadgesClass: "",
    //   Link: "/stores",
    //   col: { md: 3 },
    // },
    {
      title: "Categories",
      iconClass: "bx bxs-collection",
      description: dashboardData?.categoriesCount ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/classifications",
      col: { md: 3 },
    },
    {
      title: "Collections",
      iconClass: "bx bxs-extension",
      description: dashboardData?.totalCollections ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/collections",
      col: { md: 3 },
    },
    {
      title: "Groups",
      iconClass: "fas fa-layer-group",
      description: dashboardData?.totalGroups ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/groups",
      col: { md: 3 },
    },
    {
      title: "Coupons",
      iconClass: "fas fa-ticket-alt",
      description: dashboardData?.totalCoupons ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/groups",
      col: { md: 3 },
    },
    // {
    //   title: "Shop Products",
    //   iconClass: "bx-copy-alt",
    //   description: `${dashboardData?.shopProductsCount}`
    // },
  ]
  const classification = []

  const ids = classificationInputs?.map(i => i.name)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Dashboard" />
        <Container fluid>
          <CardTitle>Dashboard</CardTitle>
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              <Row>
                {map(TopCards, (item, key) => (
                  <Col className={item.col} key={key}>
                    <Card className={"mega-stats-wid " + item.bgColor}>
                      <Link to={item.Link}>
                        <CardBody
                          style={{ height: "200px" }}
                          className={
                            "d-flex flex-column justify-content-between " +
                            item.subList
                              ? "align-items-start"
                              : "align-items-center"
                          }
                        >
                          <div
                            className={`d-flex flex-wrap align-items-center mb-3 ${
                              item?.subList === false && "flex-column"
                            }`}
                          >
                            <div className="avatar-title revenue-avatar bg-light rounded-circle text-primary font-size-20">
                              <img
                                src={item.iconClass}
                                alt=""
                                className="w-100 h-100"
                              />
                            </div>
                            <div className="d-flex">
                              <Row className="d-flex justify-content-center">
                                <Col
                                  className={`mx-3 ${
                                    item?.subList === false && "mt-2 text-center"
                                  }`}
                                >
                                  <h5 className="mb-2">
                                    <span className="mb-2 text_md_black">
                                      {item?.title}
                                    </span>
                                  </h5>
                                  <h3 className="text_deals_green text-center">
                                    {item?.description ?? 0}
                                  </h3>
                                </Col>
                                {item?.title2 && (
                                  <Col
                                    className={`mx-3 ${
                                      item?.subList === false &&
                                      "mt-2 text-center"
                                    }`}
                                  >
                                    <h5 className="mb-2">
                                      <span className="mb-2 text_md_black">
                                        {item?.title2}
                                      </span>
                                    </h5>
                                    <h3 className="text_deals_green text-center">
                                      {item?.description2 ?? 0}
                                    </h3>
                                  </Col>
                                )}
                                {item?.title3 && (
                                  <Col
                                    className={`mx-3 ${
                                      item?.subList === false &&
                                      "mt-2 text-center"
                                    }`}
                                  >
                                    <h5 className="mb-2">
                                      <span className="mb-2 text_md_black">
                                        {item?.title3}
                                      </span>
                                    </h5>
                                    <h3 className="text_deals_green text-center">
                                      {item?.description3 ?? 0}
                                    </h3>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>

                          {map(item?.list, (subItem, subKey) => (
                            <span
                              className="d-flex align-items-center mb-2 text_md_black"
                              key={subKey}
                            >
                              <i className="bx bx-right-arrow-alt font-size-16 text_deals_yellow mx-2"></i>{" "}
                              {subItem.sbadges}{" "}
                              <p
                                className={
                                  "mb-0 mx-2 text-white fw-medium rounded-pill badge bg-" +
                                  subItem?.sbadgesClass
                                }
                                style={{ fontSize: "14px" }}
                              >
                                {subItem?.sbadgesRate}
                              </p>
                            </span>
                          ))}
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ))}
                </Row>
                <Row>
                {reports?.map((report, key) => (
                  <Col md={report.col.md} key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={report.Link}>
                          <Media>
                            <Media body>
                              <p
                                className="text-muted fw-medium d-flex align-items-center"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <i
                                  className={`font-size-16 text-success ${report.iconClass}`}
                                ></i>
                                <span className="mx-2">{report.title}</span>
                              </p>
                              <h4 className="mb-0  ">{report.description}</h4>
                            </Media>

                            {/* <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    "text-white fw-medium rounded-pill badge bg-" +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report?.sbadges}
                                </p>
                                <h4 className="mb-0">{report?.sbadgesRate}</h4>
                              </div>
                            </Media> */}

                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    "text-white fw-medium rounded-pill badge bg-" +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report.wbadges}
                                </p>
                                <h4 className="mb-0">{report.wbadgesRate}</h4>
                              </div>
                            </Media>
                          </Media>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {classification?.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    {ids?.includes(report?.value) ? (
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>

                            {/* badges with success */}
                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p className="text-white fw-medium rounded-pill bg-success badge">
                                  {report.sbadges}
                                </p>
                                <h4 className="mb-0">{report.sbadgesRate}</h4>
                              </div>
                            </Media>

                            {/* badges with warning */}
                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p className="text-white fw-medium rounded-pill bg-warning badge">
                                  {report.wbadges}
                                </p>
                                <h4 className="mb-0">{report.wbadgesRate}</h4>
                              </div>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Col>
                ))}
              </Row>
              {/* <Col className="row">
                <CardTitle>Analytics</CardTitle>
                <MonthlyChart />
              </Col> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
