import React from "react"

import MetaTag from "components/Common/Meta-tag"
import { Container } from "reactstrap"
// import Breadcrumb from "../../../components/common/Breadcrumb.js"
import Breadcrumb from "components/Common/Breadcrumb.js"
import Details from "./Details.js"

function GodownDetails() {
  return (
    <>
      <MetaTag title={"About Godown"} />

      <div className="page-content">
        <Breadcrumb title="Dashboard" breadcrumbItem="Godown" />
        <Container fluid>
          <div className="container-fluid">
            <Details />
          </div>
        </Container>
      </div>
    </>
  )
}

export default GodownDetails
