import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  FormGroup,
  Badge,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

//actions
import { getOrders } from "store/orders/actions"

import "../../assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
// import { getStores } from "store/actions"

const Orders = () => {
  const dispatch = useDispatch()

  const {
    orders,
    total,
    loading,
    defaultCurrencySymbol,
    // StoreLoading,
    // stores,
  } = useSelector(state => ({
    orders: state?.Orders?.orders,
    total: state?.Orders?.total,
    loading: state?.Orders?.loading,
    defaultCurrencySymbol:
      state.Settings.settings.company?.defaultCurrencySymbol,
    // stores: state.Stores.stores?.stores,
    // StoreLoading: state.Stores.loading,
  }))

  const [page, setPage] = useState(1)
  const [orderSearchText, setOrderSearchText] = useState("")

  // const [storeSearchText, setStoreSearchText] = useState("")
  // const [selectedStore, setSelectedStore] = useState("All Store")
  const [finalStoreId, setFinalStoreId] = useState()

  const [sort, setSort] = useState("all")
  const [isCustomStore, setIsCustomStore] = useState(false)

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const [totalItemLimit, setTotalItemLimit] = useState(10)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  useEffect(() => {
    console.log('sort: ',sort);
    dispatch(
      getOrders(
        pageSend(),
        totalItemLimit,
        orderSearchText,
        sort,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    orderSearchText,
    totalItemLimit,
    // finalStoreId,
    sort,
    isCustomStore,
    finalDateFormated,
  ])

  // useEffect(() => {
  //   dispatch(getStores(1, storeSearchText))
  // }, [dispatch, storeSearchText])

  // useEffect(() => {
  //   if (sort === "all") {
  //     setIsCustomStore(false)
  //   } else if (finalStoreId || sort) {
  //     setIsCustomStore(true)
  //   } else {
  //     setIsCustomStore(false)
  //   }
  // }, [sort, finalStoreId])

  const columns = [
    {
      dataField: "orderId",
      text: "Order ID",
      sort: true,
    },
    // {
    //   dataField: "store.storeName",
    //   text: "Store Name",
    //   sort: true,
    // },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "shippingInfo.name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "user.phone",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "totalPrice",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "paymentType",
      text: "Payment Type",
    },
    {
      dataField: "orderStatus",
      text: "Status",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleOrderStatus = status => {
    switch (status) {
      case "Processing":
        return "info"
      case "Shipped":
        return "info"
      case "Delivered":
        return "success"
      case "Cancelled":
        return "warning"
      case "Returned":
        return "danger"
      case "Fulfilled":
        return "success"
      case "Partially Fulfilled":
        return "success"
      default:
        return "info"
    }
  }

  const orderData = map(orders, item => ({
    ...item,
    orderId: <Link to={`/order/${item?._id}`}>{item?.orderId}</Link>,
    totalPrice: `${defaultCurrencySymbol && defaultCurrencySymbol} ${
      item.totalPrice
    }`,
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    // shopName: item.shop?.shopName,
    paymentType: (
      <>
        {item?.paymentType === "Cash on Delivery" ? (
          <Badge className="px-2 py-2 badge-soft-info ">COD</Badge>
        ) : (
          <Badge className="px-2 py-2 badge-soft-success ">
            {item?.paymentType}
          </Badge>
        )}
        &nbsp;
        {item?.isPaid ? (
          <Badge className="px-2 py-2 badge-soft-success ">Paid</Badge>
        ) : (
          <Badge className="px-2 py-2 badge-soft-danger">Not Paid</Badge>
        )}
      </>
    ),
    orderStatus: (
      <Badge
        className={
          "px-2 py-2 badge-soft-" + `${handleOrderStatus(item?.orderStatus)}`
        }
      >
        {" "}
        {item?.orderStatus}{" "}
      </Badge>
    ),
    activeData: (
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/order/${item?._id}`}>
          <Button id={`view`} type="button" color="white" className="ms-1 btn">
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        {/* <Link to={`/order/${item?._id}`} className="btn-light btn-sm text-info">
          View Details
        </Link> */}
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "radio",
  }

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  // const [selectedGroup, setselectedGroup] = useState(null)
  // const [totalPage, setTotalPage] = useState(10)

  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup)
  //   setTotalPage(selectedGroup?.value)
  // }

  // const optionGroup = [
  //   {
  //     // label: "Picnic",
  //     options: [
  //       { label: "10", value: "10" },
  //       { label: "20", value: "20" },
  //       { label: "50", value: "50" },
  //       { label: "100", value: "100" },
  //     ],
  //   },
  // ]

  // const handleSelect = v => {
  //   setTotalPage(v)
  // }

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
    }
  }

  // const printValue = () => {
  //   debounce(value => setStoreSearchText(value), 500)
  // }

  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)
  // function handleEnters(textEntered) {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerStoreFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  const listChange = sortValue => {
    setSort(sortValue)
  }

  // const printValue = debounce(value => setFinalInput(value), 500)

  // const handleChangeSearch = e => {
  //   printValue(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card className="pt-3">
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col className="mb-3 col-12">
                        <div
                          className="btn-group mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            checked={sort === "all"}
                            onChange={() => listChange("all")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio1"
                          >
                            All
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            checked={sort === "cancelled"}
                            onChange={() => listChange("cancelled")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio2"
                          >
                            Cancelled
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            checked={sort === "returned"}
                            id="btnradio3"
                            autoComplete="off"
                            onChange={() => listChange("returned")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio3"
                          >
                            Returned
                          </label>
                        </div>
                      </Col>
                      <Col
                        xl={4}
                        lg={6}
                        md={12}
                        sm={12}
                        className="d-flex align-items-center "
                      >
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>

                        <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                          <div className="mb-3 mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="mb-3" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalItemLimit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </Col>{" "}
                      {/* <Col xl={4} lg={6} md={6} sm={6} className="">
                        <FormGroup className="w-100 custom_store_select">
                          <div className="mb-3 ajax-select mt-lg-0 select2-container">
                            <Select
                              onInputChange={handleEnters}
                              value={selectedStore}
                              placeholder={selectedStore}
                              onChange={handlerStoreFinalValue}
                              options={storeOptions}
                              classNamePrefix="select-selection"
                              isLoading={StoreLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col
                        xl={4}
                        lg={6}
                        md={6}
                        sm={6}
                        className="parent_dateRange"
                      >
                        <div className="custom_margin_top">
                          <p
                            className="mb-0 pb-0"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Sort By Date :
                          </p>
                          <AvForm>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>

                          {/* <BasicDateRangePicker
                            setState={setSortByDate}
                            state={sortByDate}
                          /> */}
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {orders?.length ? (
                          <Row>
                            <p>Total Orders :&nbsp;{orders?.length} </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // keyField={"id"}
                                  key={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Orders!</p>
                        )}
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Orders

{
  /* <Card>
            <CardBody>
              <Col className="d-flex">
                <div className="col-3 me-5">
                  <Label>Search From</Label>
                  <Input
                    type="date"
                    onChange={e => moment(e.target.value).format("DD/MM/YYYY")}
                  />
                </div>
                <div className="col-3">
                  <Label>Search To</Label>
                  <Input
                    type="date"
                    onChange={e => moment(e.target.value).format("DD/MM/YYYY")}
                  />
                </div>
              </Col>
            </CardBody>
          </Card> */
}
