import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PAGES,
  GET_PAGE_DETAILS,
  CREATE_PAGES,
  UPDATE_PAGES,
  DELETE_PAGES,
  CREATE_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
  GET_ALL_HOME_PAGES,
  UPDATE_HOME_PAGE_STATUS,
  GET_ALL_DELETED_PAGES,
  HARD_DELETE_PAGE,
  GET_RESTORE_PAGE,
  CHECK_PAGE_TITLE,
  GET_DUPLICATE_PAGE,
  GET_DUPLICATE_SECTION,
  GET_DEFAULT_SECTION,
} from "./actionTypes"
import {
  createPagesFail,
  createPagesSuccess,
  createSectionFail,
  createSectionSuccess,
  deletePagesFail,
  deletePagesSuccess,
  deleteSectionFail,
  deleteSectionSuccess,
  getAllDeletedPagesFail,
  getAllDeletedPagesSuccess,
  getAllHomePagesFail,
  getAllHomePageSuccess,
  getPageDetailsFail,
  getPageDetailsSuccess,
  getPagesFail,
  getPagesSuccess,
  updatePagesFail,
  updatePagesSuccess,
  updateHomePageStatusSuccess,
  updateHomePageStatusFail,
  updateSectionFail,
  updateSectionSuccess,
  hardDeletePageSuccess,
  hardDeletePageFail,
  getRestorePageSuccess,
  getRestorePageFail,
  checkPageTitleSuccess,
  checkPageTitleFail,
  getDuplicatePageSuccess,
  getDuplicatePageFail,
  getDuplicateSectionSuccess,
  getDuplicateSectionFail,
  getDefaultSectionSuccess,
  getDefaultSectionFail,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function fetchPagesApi({ page, limit, searchText }) {
  return get(
    `/page/admin/all?page=${page ? page : 1}&limit=${limit}&search=${
      searchText && searchText
    }`
  )
}

const getPagesDetailsAPi = pageId => {
  return get(`/page/admin/single/${pageId}`)
}

function createPagesApi(page) {
  return post("/page/admin/new", page)
}

function updatePageApi({ page, pageId }) {
  return ApiPut(`/page/admin/${pageId}`, page)
}

function deletePagesApi(pageId) {
  return del(`/page/admin/${pageId}`)
}

function getDefaultSectionApi() {
  return get("/section/admin/default/all")
}
function createSectionApi(section) {
  return post("/section/admin/new", section)
}
function updateSectionApi({ section, sectionId }) {
  return ApiPut(`/section/admin/${sectionId}`, section)
}
function deleteSectionApi(sectionId) {
  return del(`/section/admin/${sectionId}`, sectionId)
}
function homePagesApi({ page, limit, searchText }) {
  return get(
    `/page/admin/home/all?page=${page ? page : 1}&limit=${limit}&searchText=${
      searchText && searchText
    }`
  )
}

function updateHomePageStatusApi(pageId) {
  return post(`/page/admin/home/update/${pageId}`)
}

function allDeletedPagesApi({ page, limit, searchText }) {
  return get(
    `/page/admin/deleted/all?page=${page ? page : 1}&searchText=${
      searchText && searchText
    }&limit=${limit}`
  )
}

function hardDeleteApi(pageId) {
  return del(`/page/admin/hard/${pageId}`)
}

function restorePageApi(pageId) {
  return post(`/page/admin/restore/${pageId}`)
}

//check page title

function checkPageTitleApi({ title, pageId }) {
  if (pageId) {
    return post(`/page/admin/check/title?page=${pageId}`, { title: title })
  } else return post(`/page/admin/check/title`, { title: title })
}

function duplicatePageApi({ pageId }) {
  return post(`/page/admin/duplicate/${pageId}`)
}

function duplicateSectionApi({ sectionId }) {
  return post(`/section/admin/duplicate/${sectionId}`)
}

function* fetchPages({ payload }) {
  try {
    const response = yield call(fetchPagesApi, payload)
    yield put(getPagesSuccess(response))
  } catch (error) {
    yield put(getPagesFail(error))
  }
}

function* fetchPageDetails({ payload: zestonProductId }) {
  try {
    const response = yield call(getPagesDetailsAPi, zestonProductId)
    yield put(getPageDetailsSuccess(response))
  } catch (error) {
    yield put(getPageDetailsFail(error))
  }
}

function* onCreatePage({ payload }) {
  try {
    const response = yield call(createPagesApi, payload?.pages)
    if (response?._id) {
      yield put(createPagesSuccess(response))
      Notification({
        type: "success",
        message: "Page Created Successfully!",
        title: "",
      })
      payload.history.push(`/pages/product/${response?._id}`)
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createPagesFail(error))
  }
}

function* onUpdatePage({ payload }) {
  try {
    const response = yield call(updatePageApi, payload)
    yield put(updatePagesSuccess(response))
    Notification({
      type: "success",
      message: "Page Updated Successfully!",
      title: "",
    })
    if (response) {
      if (payload?.setCloseModal) {
        payload?.setCloseModal()
      }
      try {
        const response = yield call(getPagesDetailsAPi, payload?.pageId)
        yield put(getPageDetailsSuccess(response))
      } catch (error) {
        yield put(getPageDetailsFail(error))
      }
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updatePagesFail(error))
  }
}

function* onDeletePages({ payload }) {
  try {
    const response = yield call(deletePagesApi, payload?.pageId)

    yield put(deletePagesSuccess(payload?.pageId))
    Notification({
      type: "success",
      message: "Page Deleted Successfully!",
      title: "",
    })
    if (payload?.history) {
      payload?.history.goBack()
    }
  } catch (error) {
    yield put(deletePagesFail(error))
  }
}

function* getDefaultSections({ payload }) {
  try {
    const response = yield call(getDefaultSectionApi)
    if (response) {
      yield put(getDefaultSectionSuccess(response))
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(getDefaultSectionFail(error))
  }
}

function* onCreateSection({ payload }) {
  try {
    const response = yield call(createSectionApi, payload?.section)
    if (response?._id) {
      yield put(createSectionSuccess(response))
      Notification({
        type: "success",
        message: "Section Added Successfully!",
        title: "",
      })
      payload.onCloseClick()
      try {
        const response = yield call(getPagesDetailsAPi, payload?.pageId)
        yield put(getPageDetailsSuccess(response))
      } catch (error) {
        yield put(getPageDetailsFail(error))
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createSectionFail(error))
  }
}

function* onUpdateSection({ payload }) {
  try {
    const response = yield call(updateSectionApi, payload)
    if (response?._id) {
      yield put(updateSectionSuccess(response))
      Notification({
        type: "success",
        message: "Section Updated Successfully!",
        title: "",
      })
      payload.onCloseClick()
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateSectionFail(error))
  }
}

function* onDeleteSection({ payload }) {
  try {
    const response = yield call(deleteSectionApi, payload?.sectionId)
    yield put(deleteSectionSuccess(response?._id))
    Notification({
      type: "success",
      message: "Section Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteSectionFail(error))
  }
}

function* fetchHomePagesData({ payload }) {
  try {
    const response = yield call(homePagesApi, payload)
    yield put(getAllHomePageSuccess(response))
  } catch (error) {
    yield put(getAllHomePagesFail(error))
  }
}

function* updateHomePageStatus({ payload }) {
  try {
    const response = yield call(updateHomePageStatusApi, payload)
    yield put(updateHomePageStatusSuccess(response))
  } catch (error) {
    yield put(updateHomePageStatusFail(error))
  }
}

function* fetchAllDeletedPage({ payload }) {
  try {
    const response = yield call(allDeletedPagesApi, payload)
    yield put(getAllDeletedPagesSuccess(response))
  } catch (error) {
    yield put(getAllDeletedPagesFail(error))
  }
}

function* hardDeletePage({ payload }) {
  try {
    const response = yield call(hardDeleteApi, payload)
    yield put(hardDeletePageSuccess(response))
    Notification({
      type: "success",
      message: "Page Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(hardDeletePageFail(error))
  }
}

function* fetchRestorePage({ payload }) {
  try {
    const response = yield call(restorePageApi, payload)
    yield put(getRestorePageSuccess(response))
    Notification({
      type: "success",
      message: "Page Restored Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(getRestorePageFail(error))
  }
}

//check page title

function* onCheckPageTitle({ payload }) {
  try {
    const response = yield call(checkPageTitleApi, payload)
    yield put(checkPageTitleSuccess(response))
  } catch (error) {
    yield put(checkPageTitleFail(error))
  }
}

// duplicate page

function* duplicatePage({ payload }) {
  try {
    const response = yield call(duplicatePageApi, payload)
    yield put(getDuplicatePageSuccess(response))
    Notification({
      type: "success",
      message: " Page Duplicated Successfully",
      title: "",
    })
  } catch (error) {
    yield put(getDuplicatePageFail(error))
    Notification({
      type: "error",
      message: error.response?.data?.message,
      title: "",
    })
  }
}

// duplicate section

function* duplicateSection({ payload }) {
  try {
    const response = yield call(duplicateSectionApi, payload)
    if (response?._id) {
      payload.handleUpdatePage(response?._id)
      yield put(getDuplicateSectionSuccess(response))
      Notification({
        type: "success",
        message: " Section Duplicated Successfully",
        title: "",
      })
    }
  } catch (error) {
    yield put(getDuplicateSectionFail(error))
    Notification({
      type: "error",
      message: error.response?.data?.message,
      title: "",
    })
  }
}

function* pagesSaga() {
  yield takeEvery(GET_PAGES, fetchPages)
  yield takeEvery(GET_PAGE_DETAILS, fetchPageDetails)
  yield takeEvery(CREATE_PAGES, onCreatePage)
  yield takeEvery(UPDATE_PAGES, onUpdatePage)
  yield takeEvery(DELETE_PAGES, onDeletePages)
  yield takeEvery(CREATE_SECTION, onCreateSection)
  yield takeEvery(GET_DEFAULT_SECTION, getDefaultSections)
  yield takeEvery(UPDATE_SECTION, onUpdateSection)
  yield takeEvery(DELETE_SECTION, onDeleteSection)
  yield takeEvery(GET_ALL_HOME_PAGES, fetchHomePagesData)
  yield takeEvery(UPDATE_HOME_PAGE_STATUS, updateHomePageStatus)
  yield takeEvery(GET_ALL_DELETED_PAGES, fetchAllDeletedPage)
  yield takeEvery(HARD_DELETE_PAGE, hardDeletePage)
  yield takeEvery(GET_RESTORE_PAGE, fetchRestorePage)
  yield takeEvery(CHECK_PAGE_TITLE, onCheckPageTitle)
  yield takeEvery(GET_DUPLICATE_PAGE, duplicatePage)
  yield takeEvery(GET_DUPLICATE_SECTION, duplicateSection)
}

export default pagesSaga
