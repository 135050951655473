import { AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { createBanner } from "store/actions"
import MetaTag from "components/Common/Meta-tag"
import Form from "./Form"

const CreateBanner = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.Banner.loading,
  }))

  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [appBannerImagesPreview, setAppBannerImagesPreview] = useState([])

  function handleValidSubmit(values) {
    const createBannerData = {
      tag: values.tag,
      priority: parseInt(values.priority),
      webImages: bannerImagesPreview,
      appImages: appBannerImagesPreview,
      type: values.type,
      link: values.link,
    }
    dispatch(createBanner(createBannerData, history))
  }

  const formOption = {
    bannerImagesPreview,
    setBannerImagesPreview,
    appBannerImagesPreview,
    setAppBannerImagesPreview
  }

  return (
    <>
      <MetaTag title={"Create Banner"} />

      <div className="page-content">
        <Breadcrumbs title="Banners" breadcrumbItem="Create Banner" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4"></CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Form formOption={formOption} />
                      <Row>
                        <Col>
                          <div className="m-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateBanner
