import React, { Fragment, useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../MyPagination"

import "../../assets/scss/datatables.scss"

import noImage from "../../assets/images/product/noimage3.png"
import {
  deleteGodown,
  getAllGodown,
  getSettings,
  getStores,
  updateGodown,
} from "store/actions"
import GodownCreateModal from "./Crud/godownCreateModal"

const Godowns = () => {
  const dispatch = useDispatch()

  const { godown, total, loading } = useSelector(state => ({
    godown: state.Godown.godowns?.godowns,
    total: state.Godown.godowns?.total,
    loading: state?.Godown?.tableLoading,
  }))

  const [page, setPage] = useState(1)

  const [searchText, setSearchText] = useState("")
  const [sort, setSort] = useState("all")

  const [pagination, setPagination] = useState(10)

  //pagination
  const totalPages = Math.ceil(total / pagination)

  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  useEffect(() => {
    dispatch(getAllGodown(pageSend(), pagination, searchText))
  }, [dispatch, pageSend(), pagination, searchText])

  const handleDelete = godownId => {
    dispatch(deleteGodown(godownId))
  }

  const handleSwitchChange = (item, isActive) => {
    const finalData = {
      ...item,
      isActive: isActive,
    }

    dispatch(updateGodown(finalData, item?._id, sort))
  }

  const columns = [
    {
      dataField: "name",
      filterText: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "place",
      filterText: "place",
      text: "Place",
      sort: true,
    },
    {
      dataField: "phone",
      filterText: "phone",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "isActive",
      filterText: "isActive",
      text: "Status",
      sort: true,
    },
    // {
    //   dataField: "priceData",
    //   filterText: "sellingPrice",
    //   text: "Price",
    //   sort: true,
    // },
    // {
    //   dataField: "store.storeName",
    //   filterText: "store",
    //   text: "Store",
    //   sort: true,
    // },

    // {
    //   dataField: "activeData",
    //   filterText: "status",
    //   text: "Status",
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  //   const inputs = productInputs?.map(i => i.name)

  //   const modifiedColumns = columns?.filter(col =>
  //     inputs?.includes(col?.filterText)
  //   )

  const finalCol = [
    ...columns,
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const colors = ["danger", "success", "info"]

  //all
  const allGodownData = map(godown, (item, index) => ({
    ...item,
    key: item?._id,
    name: <span>{item?.name}</span>,

    place: <span>{item?.place}</span>,

    phone: (
      <>
        <p>{item?.phone}</p>
      </>
    ),

    isActive: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={e => {
              handleSwitchChange(item, e.target.checked)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),

    action: (
      <>
        <div>
          <Link to={`/godownDetails/${item?._id}`}>
            <Button type="button" color="red" className="mb-2 ">
              <i
                className="mdi mdi-eye"
                style={{ color: "blue", fontSize: "22px", textAlign: "center" }}
              />
            </Button>
          </Link>

          <i
            onClick={() => handleDelete(item?._id)}
            className="bx bx-trash me-2 cursor-pointer"
            style={{ color: "red", fontSize: "20px" }}
          />
        </div>
      </>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const limitValues = [10, 20, 50, 100]

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setPagination(e.target.value)
    }
  }

  // const handleEnters = textEntered => {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerProductFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  const [godownModal, setGodownModal] = useState(false)

  return (
    <React.Fragment>
      <Row>
        <GodownCreateModal
          show={godownModal}
          onCloseClick={() => setGodownModal(false)}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={allGodownData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={4} lg={6} md={6} sm={6} className="d-flex ">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e =>
                                    debounceProductSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                          <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                            <div className="mb-3 mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="mb-3" style={{ width: "50px" }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={pagination}
                                onChange={e => handlePageLimit(e)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm>
                          {/* <Col lg={2} sm={2} className="mx-2">
                            <AvForm className="form-horizontal">
                              <div className="mb-3" style={{ width: "80px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={pagination}
                                  min={1}
                                  onChange={e => handlePageLimit(e)}
                                  type="number"
                                  required
                                />
                              </div>
                            </AvForm>
                          </Col> */}
                          {/* {products.length < 10 ? (
                            <div className="mx-4">
                              <select
                                name="pagenation"
                                id="page"
                                style={{
                                  height: "80%",
                                  padding: "0 20px",
                                  borderColor: "#d0d6db",
                                }}
                                className="rounded"
                                onChange={e => handlePageLimit(e)}
                              >
                                {map(limitValues, (item, key) => (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={() => setGodownModal(true)}
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Godown
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {total >= 1 ? (
                            <>
                              <Row>
                                <p>Total :&nbsp;{total} </p>

                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      key={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      // onTableChange={handleTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                pages={pages}
                                clcickedPage={page}
                                onNunClick={item => setPage(item)}
                                onNextClick={() => setPage(page + 1)}
                                onPrevClick={() => setPage(page - 1)}
                                apiPage={pageSend}
                              />
                            </>
                          ) : (
                            <p className="my-4 mx-3 ">No {sort} Godown !</p>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Godowns
