import {
  GET_PAGE_DETAILS,
  UPDATE_PAGES,
  CREATE_PAGES,
  DELETE_PAGES_FAIL,
  UPDATE_PAGES_FAIL,
  CREATE_PAGES_FAIL,
  GET_PAGE_DETAILS_FAIL,
  GET_PAGES_FAIL,
  GET_PAGES_SUCCESS,
  GET_PAGE_DETAILS_SUCCESS,
  CREATE_PAGES_SUCCESS,
  UPDATE_PAGES_SUCCESS,
  DELETE_PAGES_SUCCESS,
  GET_PAGES,
  CREATE_SECTION_FAIL,
  CREATE_SECTION,
  CREATE_SECTION_SUCCESS,
  DELETE_SECTION,
  UPDATE_SECTION,
  UPDATE_SECTION_FAIL,
  DELETE_SECTION_FAIL,
  UPDATE_SECTION_SUCCESS,
  DELETE_SECTION_SUCCESS,
  GET_ALL_HOME_PAGES,
  GET_ALL_HOME_PAGES_FAIL,
  GET_ALL_HOME_PAGES_SUCCESS,
  UPDATE_HOME_PAGE_STATUS,
  UPDATE_HOME_PAGE_STATUS_FAIL,
  UPDATE_HOME_PAGE_STATUS_SUCCESS,
  GET_ALL_DELETED_PAGES,
  GET_ALL_DELETED_PAGES_FAIL,
  GET_ALL_DELETED_PAGES_SUCCESS,
  HARD_DELETE_PAGE,
  HARD_DELETE_PAGE_SUCCESS,
  HARD_DELETE_PAGE_FAIL,
  RESTORE_PAGE,
  RESTORE_PAGE_SUCCESS,
  RESTORE_PAGE_FAIL,
  GET_RESTORE_PAGE,
  GET_RESTORE_PAGE_SUCCESS,
  GET_RESTORE_PAGE_FAIL,
  CHECK_PAGE_TITLE_SUCCESS,
  CHECK_PAGE_TITLE,
  CHECK_PAGE_TITLE_FAIL,
  GET_DUPLICATE_PAGE,
  GET_DUPLICATE_PAGE_SUCCESS,
  GET_DUPLICATE_PAGE_FAIL,
  GET_DUPLICATE_SECTION,
  GET_DUPLICATE_SECTION_SUCCESS,
  GET_DUPLICATE_SECTION_FAIL,
  GET_DEFAULT_SECTION,
  GET_DEFAULT_SECTION_FAIL,
  GET_DEFAULT_SECTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  pages: [],
  pageDetails: {},
  error: {},
  loading: false,
  sectionLoad: false,

  section: {},
  homePages: [],
  homePageDetails: {},
  deleted: [],
  pageTitleCheck: {},
  pageDuplicate: {},
  sectionDuplicate: {},

  defaultSections: [],
  defaultSectionLoad: false,
}

const Pages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAGES:
    case GET_PAGE_DETAILS:
    case UPDATE_PAGES:
    case CREATE_PAGES:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SECTION:
    case DELETE_SECTION:
    case UPDATE_SECTION:
      return {
        ...state,
        sectionLoad: true,
      }

    case GET_DEFAULT_SECTION:
      return {
        ...state,
        defaultSectionLoad: true,
      }
    case GET_DEFAULT_SECTION_FAIL:
      return {
        ...state,
        defaultSectionLoad: false,
        error: action.payload,
      }
    case GET_DEFAULT_SECTION_SUCCESS:
      return {
        ...state,
        defaultSectionLoad: false,
        defaultSections: action.payload,
      }

    case GET_ALL_HOME_PAGES:
    case UPDATE_HOME_PAGE_STATUS:
    case GET_ALL_DELETED_PAGES:
      return {
        ...state,
        loading: true,
      }

    case DELETE_PAGES_FAIL:
    case UPDATE_PAGES_FAIL:
    case CREATE_PAGES_FAIL:
    case GET_PAGE_DETAILS_FAIL:
    case GET_PAGES_FAIL:
    case CREATE_SECTION_FAIL:
    case UPDATE_SECTION_FAIL:
    case DELETE_SECTION_FAIL:
    case GET_ALL_HOME_PAGES_FAIL:
    case UPDATE_HOME_PAGE_STATUS_FAIL:

    case GET_ALL_DELETED_PAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        sectionLoad: false,
      }

    case HARD_DELETE_PAGE:
      return {
        ...state,
        loading: true,
      }

    case HARD_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: {
          ...state.pages,
          pages: state?.deleted?.pages?.filter(
            i => i?._id !== action.payload?._id
          ),
        },
      }

    case HARD_DELETE_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_RESTORE_PAGE:
      return {
        ...state,
        loading: true,
      }

    case GET_RESTORE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: {
          ...state.deleted,
          pages: state?.deleted?.pages?.filter(
            i => i?._id !== action.payload?._id
          ),
        },
        error: {},
      }

    case GET_RESTORE_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CHECK_PAGE_TITLE:
      return {
        ...state,
        loading: true,
      }
    case CHECK_PAGE_TITLE_SUCCESS:
      return {
        ...state,
        pageTitleCheck: action.payload,
        loading: false,
      }

    case CHECK_PAGE_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // duplicate page

    case GET_DUPLICATE_PAGE:
      return {
        ...state,
        // loading: true,
      }
    case GET_DUPLICATE_PAGE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          pages: [action.payload, ...state.pages.pages],
        },
        loading: false,
      }

    case GET_DUPLICATE_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    // duplicate section

    case GET_DUPLICATE_SECTION:
      return {
        ...state,
      }
    case GET_DUPLICATE_SECTION_SUCCESS:
      return {
        ...state,
        pageDetails: {
          ...state.pageDetails,
          sections: [action.payload, ...state.pageDetails.sections],
        },
        loading: false,
      }

    case GET_DUPLICATE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        error: {},
        loading: false,
      }
    case GET_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        pageDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_PAGES_SUCCESS:
      return {
        ...state,
        // pages: [...state.pages, action.payload],
        error: {},
        loading: false,
      }

    case UPDATE_PAGES_SUCCESS:
      return {
        ...state,
        pages: {
          pages: state?.pages?.pages?.map(i =>
            i?._id === action.payload?._id ? action.payload : i
          ),
        },
        error: {},
        loading: false,
      }

    case DELETE_PAGES_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          pages: state.pages.pages?.filter(i => i._id !== action.payload),
        },
        homePages: state.homePages?.filter(i => i._id !== action.payload),
        // deleted: {
        //   ...state.pages,
        //   pages: state?.deleted?.pages?.filter(i => i?._id !== action.payload),
        // },

        error: {},
        loading: false,
      }

    case CREATE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        section: action.payload,
      }
    case UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        section: action.payload,
        pageDetails: {
          ...state.pageDetails,
          sections: state?.pageDetails?.sections?.map(item =>
            item?._id === action?.payload?._id ? { ...action?.payload } : item
          ),
        },
      }
    case DELETE_SECTION_SUCCESS:
      return {
        ...state,
        sectionLoad: false,
        pageDetails: {
          ...state.pageDetails,
          sections: state?.pageDetails?.sections?.filter(
            section => section?._id !== action.payload
          ),
        },
      }

    case GET_ALL_HOME_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        homePages: action.payload,
      }

    case UPDATE_HOME_PAGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        homePages: state?.homePages?.map(item =>
          item?._id === action?.payload?._id
            ? { ...action?.payload }
            : { ...item, isActive: false }
        ),
      }

    case GET_ALL_DELETED_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: action.payload,
      }

    default:
      return state
  }
}

export default Pages
