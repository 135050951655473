import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  GET_USER_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS_SUCCESS,
  GET_USER_ORDER_DETAILS_FAIL,
  UPDATE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  GET_STARRED_ORDERS,
  GET_STARRED_ORDERS_SUCCESS,
  GET_STARRED_ORDERS_FAIL,
  GET_CANCELLED_ORDERS,
  GET_CANCELLED_ORDERS_SUCCESS,
  GET_CANCELLED_ORDERS_FAIL,
  GET_RETURNED_ORDERS,
  GET_RETURNED_ORDERS_SUCCESS,
  GET_RETURNED_ORDERS_FAIL,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
} from "./actionTypes"

//all orders
export const getOrders = (
  page,
  limit,
  searchText,
  sort,
  sortByDate
) => ({
  type: GET_ORDERS,
  payload: { page, limit, searchText, sort, sortByDate },
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
})

//order details
export const getOrderDetails = orderId => ({
  type: GET_ORDER_DETAILS,
  payload: orderId,
})

export const getOrderDetailsSuccess = orderDetails => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: orderDetails,
})

export const getOrderDetailsFail = error => ({
  type: GET_ORDER_DETAILS_FAIL,
  payload: error,
})

//USER order details
export const getUserOrderDetails = (userId, page) => ({
  type: GET_USER_ORDER_DETAILS,
  payload: { userId, page },
})

export const getUserOrderDetailsSuccess = userOrderDetails => ({
  type: GET_USER_ORDER_DETAILS_SUCCESS,
  payload: userOrderDetails,
})

export const getUserOrderDetailsFail = error => ({
  type: GET_USER_ORDER_DETAILS_FAIL,
  payload: error,
})

//Update ITEM
export const updateOrderItem = (order, orderId, history) => ({
  type: UPDATE_ORDER_ITEM,
  payload: { order, orderId, history },
})

export const updateOrderItemSuccess = order => ({
  type: UPDATE_ORDER_ITEM_SUCCESS,
  payload: order,
})

export const updateOrderItemFail = error => ({
  type: UPDATE_ORDER_ITEM_FAIL,
  payload: error,
})

//UPDATE ORDER
export const updateOrder = (order, history) => ({
  type: UPDATE_ORDER,
  payload: { order, history },
})

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
})

export const updatePayment = order => ({
  type: UPDATE_PAYMENT,
  payload: { order },
})

export const updatePaymentSuccess = order => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: order,
})

export const updatePaymentFail = error => ({
  type: UPDATE_PAYMENT_FAIL,
  payload: error,
})

//all starred orders
export const getStarredOrders = page => ({
  type: GET_STARRED_ORDERS,
  payload: { page },
})

export const getStarredOrdersSuccess = starredOrders => ({
  type: GET_STARRED_ORDERS_SUCCESS,
  payload: starredOrders,
})

export const getStarredOrdersFail = error => ({
  type: GET_STARRED_ORDERS_FAIL,
  payload: error,
})

//all cancelled orders
export const getCancelledOrders = page => ({
  type: GET_CANCELLED_ORDERS,
  payload: { page },
})

export const getCancelledOrdersSuccess = cancelledOrders => ({
  type: GET_CANCELLED_ORDERS_SUCCESS,
  payload: cancelledOrders,
})

export const getCancelledOrdersFail = error => ({
  type: GET_CANCELLED_ORDERS_FAIL,
  payload: error,
})

//all returned orders
export const getReturnedOrders = page => ({
  type: GET_RETURNED_ORDERS,
  payload: { page },
})

export const getReturnedOrdersSuccess = returnedOrders => ({
  type: GET_RETURNED_ORDERS_SUCCESS,
  payload: returnedOrders,
})

export const getReturnedOrdersFail = error => ({
  type: GET_RETURNED_ORDERS_FAIL,
  payload: error,
})
