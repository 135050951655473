import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import {
  getBanners,
  addNewBanner as createBanner,
  deleteBanner,
} from "store/banner/actions"

import "../../assets/scss/datatables.scss"

import noImage from "../../assets/images/product/noimage3.png"
import MyPagination from "pages/MyPagination"
import DeleteModal from "components/Common/DeleteModal"

const Banners = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { banners, loading } = useSelector(state => ({
    banners: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])

  const totalPages = Math.ceil(banners?.length / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteBanner(isOpen?.id))
  }

  const columns = [
    {
      dataField: "tag",
      text: "Tag",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "imageData",
      text: "Image",
      sort: true,
    },
    {
      dataField: "link",
      text: "Link",
      sort: true,
    },
    {
      dataField: "clicksCount",
      text: "Clicks Count",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const productData = map(banners, (item, index) => ({
    ...item,
    imageData: (
      <div>
        <img
          src={item?.webImages? item?.webImages[0]?.url : noImage}
          width="500px"
          height="50%"
        />
      </div>
    ),

    action: (
      <div>
        <Link to={`/banner/update/${item?._id}`} className="btn-md">
          <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: banners?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setZproductList(
      banners.filter(zproduct =>
        Object.keys(zproduct).some(key =>
          zproduct[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.stauts}
          onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Link to="/banner/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Banner
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {banners?.length >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              pages={pages}
                              clcickedPage={page}
                              onNunClick={item => setPage(item)}
                              onNextClick={() => setPage(page + 1)}
                              onPrevClick={() => setPage(page - 1)}
                              apiPage={pageSend}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Banners !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Banners
